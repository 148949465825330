<template>
  <div>
    <!-- Show progress bar while loading -->
    <div v-if="isLoading" class="progress-container">
      <div class="progress-bar"></div>
    </div>

    <div v-else>
      <div class="row">
        <!-- Use a flex container to display chart containers horizontally -->
        <div class="d-flex">
          <div
            v-for="(chartData, index) in chartDataList"
            :key="index"
            class="chart-container"
          >
            <div class="chart-header">
              <h3 class="chart-title">{{ chartData.title }}</h3>
            </div>
            <div class="chart-scroll-container">
              <canvas :id="'myDonutCharts-' + index" width="400" height="400"></canvas>
            </div>
            <p class="total-value">Total Value: {{ chartData.totalValue }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Divide chart containers into two equal columns */
.d-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Distribute items equally between columns */
  }
  
  .chart-container {
    width: calc(50% - 10px); /* 50% width minus some margin/padding to create spacing between columns */
    margin-bottom: 20px; /* Add spacing between chart containers */
  }
  
  .chart-container {
    width: 30%; /* Set the width to 50% of the parent container */
  
    text-align: center;
    margin-bottom: 20px; /* Add spacing between chart containers */
  }
  
  .chart-title {
    margin-top: 10px; /* Add top margin for title */
  }
  .progress-container {
    text-align: center;
    margin: 20px 0; /* Add spacing around the progress bar */
  }
  
  .progress-bar {
    width: 100px; /* Adjust the width of the progress bar */
    height: 10px; /* Adjust the height of the progress bar */
    background-color: #007BFF; /* Set the background color of the progress bar */
    margin: 0 auto; /* Center the progress bar horizontally */
    animation: loading 2s linear infinite; /* Add animation for loading effect */
  }
@keyframes loading {
  0% {
    width: 0; /* Start with 0% width */
  }
  100% {
    width: 100%; /* End with 100% width */
  }
}
</style>

<script>
import { Chart } from 'chart.js';
import axios from 'axios';

export default {
  props: {
    selectedKelurahans: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chartDataList: [],
      isLoading: true,
      totalQuestion: 0,
      refreshInterval: null, // Store the interval ID for refreshing data
    };
  },

  methods: {
    handleChartClick(chartData) {
      // This method is called when a chart container is clicked.
      // You can perform any desired actions with the clicked chartData.
      console.log("Clicked on chart with title: " + chartData.id);
      this.$router.push({ 
      name: 'Blank', 
      params: { 
        id: chartData.id // Replace 'id' with the actual parameter name you want to pass
      }, 
      query: { 
        qId: chartData.id, 
        question: chartData.title // Add query parameters as needed
      } 
    });
    },
    getQuestion() {
      axios
        .get('/api/v1/questionares/show?type=text', {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        })
        .then((response) => {
          console.log(response);
          this.totalQuestion = response.data.length;
          response.data.forEach((q) => {
            if (q.question_type != 'devider' && q.question_type != 'attachment' && q.question_type === 'text' && q.question !== 'Nama Lengkap Responden' && q.question !== 'Alamat' && q.question !== 'Nomer HP') {
              this.getAnswers(q);
            }
          });
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
          this.isLoading = false;
        });
    },

    async getAnswers(questionId) {
      const response = await axios.get('/api/v1/dashboard/aggregate-answers?q_id=' + questionId.id + '&kode=' + this.$props.selectedKelurahans, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } });
     console.log(this.$props.selectedKelurahans, 'props kelurahan guys');
      const labels = [];
      const data = [];
      let totalValue = 0; // Calculate the total value

      for (const key in response.data) {
        if (Object.prototype.hasOwnProperty.call(response.data, key)) {
          const responsescampuran = key;
          labels.push(responsescampuran);
          data.push(response.data[key]);
          totalValue += response.data[key];
        }
      }

      const optionsObject = {
        id: questionId.id,
        title: questionId.question,
        labels: labels,
        data: data,
        totalValue: totalValue,
      };

      // Find the existing chart data and update it if it exists
      const existingDataIndex = this.chartDataList.findIndex((chartData) => chartData.title === optionsObject.title);
      if (existingDataIndex !== -1) {
        this.chartDataList[existingDataIndex] = optionsObject;
      } else {
        this.chartDataList.push(optionsObject);
      }
      console.log('data list', this.chartDataList);
    },
    createCharts() {
      this.chartDataList.forEach((chartData, index) => {
       
        const canvasId = 'myDonutCharts-' + index;
        const canvasElement = document.getElementById(canvasId);

        // Check if the canvas element exists
        if (canvasElement) {
          const ctx = canvasElement.getContext('2d');
          const existingChart = Chart.getChart(ctx);

          // Destroy the existing chart if it exists
          if (existingChart) {
            existingChart.destroy();
          }

          new Chart(ctx, {
  type: 'doughnut',
  data: {
    labels: chartData.labels,
    datasets: [
      {
        data: chartData.data,
      },
    ],
  },
  options: {
    parsing: false,
    normalized: true,
    animation: false,
    responsive: false, // Disable chart responsiveness
    maintainAspectRatio: false, // Disable aspect ratio constraints
    width: 50, // Set the width to 50 pixels
    height: 200, // Set the height to 200 pixels (adjust as needed)
    plugins: {
      legend: {
        display: true, // Hide the legend
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.label || ''; // Ensure there's a label
            const value = (context.parsed / context.dataset.data.reduce((a, b) => a + b)) * 100; // Calculate percentage
            return label + ': ' + value.toFixed(2) + '%'; // Format and display the percentage
          },
        },
      },
    },
    onClick: function (event, elements) {
      if (elements.length > 0) {
        // Extract relevant data from the clicked segment
        const clickedLabel = chartData.labels[elements[0].index];
        const clickId = chartData.id;
        console.log('chart data nih bos',chartData.id);
        // Construct the URL with the data
        const queryParams = `question=${clickedLabel}&qId=${clickId}`;
        window.location.href = `/blank?${queryParams}`;
      }
    },
  },
});


        }
      });
    },
    startDataRefresh() {
      // Set up a timer to periodically refresh chart data
      this.refreshInterval = setInterval(() => {
        this.isLoading = true; // Show the progress bar while loading
        this.getQuestion();
        this.createCharts();
        this.isLoading = false; // Hide the progress bar when loading is complete
      }, 1000); // Refresh data every 60 seconds (adjust as needed)
    },
    stopDataRefresh() {
      // Clear the data refresh timer
      clearInterval(this.refreshInterval);
    },
  },
  mounted() {
    this.getQuestion();
    this.createCharts();
    this.startDataRefresh(); // Start the data refresh timer
  },
  beforeUnmount() {
    this.stopDataRefresh(); // Stop the data refresh timer when the component is destroyed
  },
  watch: {
    totalQuestion(newTotal) {
      if (newTotal === this.chartDataList.length) {
        this.isLoading = false;
      }
    },
  },
};
</script>
