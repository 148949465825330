<template>
    <div class="modal">
      <div class="modal-content">
        <img :src="imageUrl" alt="Full-sized Image" />
      </div>
      <button @click="closeModal" class="modal-close-button">Close</button>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      imageUrl: String,
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add your modal styles here */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    max-width: 90%;
    max-height: 90%;
  }
  
  .modal img {
    max-width: 100%;
    max-height: 100%;
  }
  
  .modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #fff;
    border: none;
    cursor: pointer;
  }
  </style>
  