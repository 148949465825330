<template>
  <div>
    <div class="grid grid-cols-4 gap-6">
      <!-- Select Province -->
      <div class="col-span-1 mb-5">
        <label for="provinceSelect">Select Province:</label>
        <select
          v-model="selectedProvince"
          @change="getCity"
          id="provinceSelect"
          class="block w-full mt-2 p-2 border rounded-md"
        >
          <!-- Populate the options dynamically from the 'province' variable -->
          <option
            v-for="prov in province"
            :value="prov.kode_provinsi"
            :key="prov.kode_provinsi"
          >
            {{ prov.nama_provinsi }}
          </option>
        </select>
      </div>
      <!-- Select Kabkot (City) -->
      <div class="col-span-1">
        <label for="kabkotSelect">Select Kabkot:</label>
        <select
          v-model="selectedCity"
          @change="getKecamatan"
          id="kabkotSelect"
          class="block w-full mt-2 p-2 border rounded-md"
        >
          <!-- Populate the options dynamically based on the selected province -->
          <option v-for="c in city" :value="c.kode_kabkot" :key="c.kode_kabkot">
            {{ c.nama_kabkot }}
          </option>
        </select>
      </div>
      <!-- Select Kecamatan (Sub-district) -->
      <div class="col-span-1">
        <label for="kecamatanSelect">Select Kecamatan:</label>
        <select
          v-model="selectedKecamatan"
          @change="getKelurahan"
          id="kecamatanSelect"
          class="block w-full mt-2 p-2 border rounded-md"
        >
          <!-- Populate the options dynamically based on the selected city -->
          <option
            v-for="kec in kecamatan"
            :value="kec.kode_kecamatan"
            :key="kec.kode_kecamatan"
          >
            {{ kec.nama_kecamatan }}
          </option>
        </select>
      </div>
      <!-- Select Desa (Village) -->
      <div class="col-span-1">
        <label for="desaSelect">Select Desa:</label>
        <select
          v-model="selectedKelurahan"
          id="desaSelect"
          class="block w-full mt-2 p-2 border rounded-md"
        >
          <!-- Populate the options dynamically based on the selected kecamatan -->
          <option
            v-for="desa in kelurahan"
            :value="desa.kode_deskel"
            :key="desa.kode_deskel"
          >
            {{ desa.nama_deskel }}
          </option>
        </select>
      </div>
    </div>
    <!-- Vue2Leaflet Map Component -->
    <l-map
      ref="map"
      v-model:zoom="zoom"
      :center="center"
      style="height: 1000px; width: 100%"
    >
      <!-- Conditionally render the Tile Layer -->
      <l-tile-layer
        v-if="!isLoading"
        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        layer-type="base"
        name="OpenStreetMap"
      ></l-tile-layer>

      <!-- Loading Indicator with Progress Bar -->
      <div v-if="isLoading" class="loading-indicator">
        <div class="progress-bar"></div>
        <p>Please wait.</p>
      </div>

      <!-- Conditionally render dynamic markers when data is available -->
      <l-marker
        v-else-if="!isLoading"
        v-for="(marker, index) in extractedData"
        :lat-lng="[marker.lat, marker.lng]"
        :key="index"
      ></l-marker>
    </l-map>

    <!-- Button to Toggle Full Screen -->
    <button @click="toggleFullScreen">Toggle Full Screen</button>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";
import axios from "axios";
import L from "leaflet";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  data() {
    return {
      dynamic: [], // Array to store marker coordinates
      zoom: 5, // Initial zoom level
      center: [-6.2411029, 106.8418063], // Replace with your desired center coordinates
      isFullScreen: false, // Full-screen mode flag
      isLoading: true, // Loading indicator flag
      province: [], // Store province data
      selectedProvince: null,
      city: [], // Store city data
      selectedCity: null,
      kecamatan: [],
      selectedKecamatan: null,
      kelurahan: [],
      selectedKelurahan: null,
      extractedData: [],
    };
  },
  methods: {
    getProvince() {
      axios
        .get("https://maps.desapresisi.id/api/provinsis/", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.province = response.data;
        })
        .catch((error) => {
          console.error("Error fetching province data:", error);
        });
    },
    getCity() {
      axios
        .get(
          "https://maps.desapresisi.id/api/kabkot?kode=" +
            this.selectedProvince,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.city = response.data;
        })
        .catch((error) => {
          console.error("Error fetching city data:", error);
        });
    },
    getKecamatan() {
      axios
        .get(
          "https://maps.desapresisi.id/api/kecamatan?kode=" + this.selectedCity,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.kecamatan = response.data;
        })
        .catch((error) => {
          console.error("Error fetching kecamatan data:", error);
        });
    },
    getKelurahan() {
      axios
        .get(
          "https://maps.desapresisi.id/api/deskel?kode=" +
            this.selectedKecamatan,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.kelurahan = response.data;
        })
        .catch((error) => {
          console.error("Error fetching kelurahan data:", error);
        });
    },
    // Method to toggle full-screen mode
    toggleFullScreen() {
      const mapContainer = this.$refs.map.mapObject._container;
      if (!this.isFullScreen) {
        mapContainer.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
      this.isFullScreen = !this.isFullScreen;
    },
    // Method to fetch map data
    getMap(qId, question) {
      // Simulate a 10-second delay to show the progress bar
      setTimeout(() => {
        console.log("qId:", qId);
        console.log("question:", question);
        this.isLoading = false; // Hide loading indicator
        axios
          .get(
            "/api/v1/answers/get-sebaran?q_id=" +
              qId +
              "&content=" +
              question +
              "&kode=" +
              +{
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
          )
          .then((response) => {
            // Log the response for debugging
            console.log("Received map data:", response);
            // Update the 'dynamic' array with marker coordinates
            this.dynamic = response.data;
            this.extractedData = this.dynamic.map((item) => item.coordinate);
          })
          .catch((error) => {
            console.error("Error fetching map data:", error);
            // Handle errors here
          });
      }, 10000); // 10-second delay
    },
  },
  mounted() {
    console.log("coba coba ada", this.$route.query);
    const queryParameters = this.$route.query;
    const qId = queryParameters.qId;
    const question = queryParameters.question;

    // Now you have 'qId' and 'question' available for use

    this.getMap(qId, question);
    this.getProvince();
  },
};
</script>

<style>
/* CSS styles for the loading indicator and progress bar */
.loading-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  text-align: center;
}

.progress-bar {
  width: 0%;
  height: 20px;
  background-color: #ccc;
  position: relative;
}

.progress-bar::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background-color: #4caf50; /* Green color for progress */
  animation: progressBar 10s linear forwards;
}

@keyframes progressBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
</style>
