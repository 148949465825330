<template>
  <div>
    <!-- Breadcrumb -->
    <Breadcrumb breadcrumb="table" />

    <div class="mt-4">
      <h3 class="text-gray-600">Data Pemilih dan Responden</h3>

      <button @click="exportTableDataToCSV" class="elegant-button">
        Export to CSV
      </button>
      <div class="mt-6">
        <div class="grid grid-cols-4 gap-6 mb-6">
          <!-- Select Province -->
          <div class="col-span-1">
            <label for="provinceSelect">Select Province:</label>
            <select
              v-model="selectedProvince"
              @change="getSelected"
              id="provinceSelect"
              class="block w-full mt-2 p-2 border rounded-md"
            >
              <!-- Populate the options dynamically from the 'province' variable -->
              <option
                v-for="prov in province"
                :value="prov.kode_provinsi"
                :key="prov.kode_provinsi"
              >
                {{ prov.nama_provinsi }}
              </option>
            </select>
          </div>
          <!-- Select Kabkot (City) -->
          <div class="col-span-1">
            <label for="kabkotSelect">Select Kabkot:</label>
            <select
              v-model="selectedCity"
              @change="getSelected"
              id="kabkotSelect"
              class="block w-full mt-2 p-2 border rounded-md"
            >
              <!-- Populate the options dynamically based on the selected province -->
              <option
                v-for="c in city"
                :value="c.kode_kabkot"
                :key="c.kode_kabkot"
              >
                {{ c.nama_kabkot }}
              </option>
            </select>
          </div>
          <!-- Select Kecamatan (Sub-district) -->
          <div class="col-span-1">
            <label for="kecamatanSelect"> Select Kecamatan:</label>
            <select
              v-model="selectedKecamatan"
              @change="getSelected"
              id="kecamatanSelect"
              class="block w-full mt-2 p-2 border rounded-md"
            >
              <!-- Populate the options dynamically based on the selected city -->
              <option
                v-for="kec in kecamatan"
                :value="kec.kode_kecamatan"
                :key="kec.kode_kecamatan"
              >
                {{ kec.nama_kecamatan }}
              </option>
            </select>
          </div>
          <!-- Select Desa (Village) -->
          <div class="col-span-1">
            <label for="desaSelect">Select Desa:</label>
            <select
              v-model="selectedKelurahan"
              id="desaSelect"
              class="block w-full mt-2 p-2 border rounded-md"
            >
              <!-- Populate the options dynamically based on the selected kecamatan -->
              <option
                v-for="desa in kelurahan"
                :value="desa.kode_deskel"
                :key="desa.kode_deskel"
              >
                {{ desa.nama_deskel }}
              </option>
            </select>
          </div>
        </div>
        <div class="table-container">
          <table class="min-w-full leading-normal">
            <thead>
              <tr>
                <th
                  v-for="(header, index) in questionFix"
                  :key="index"
                  class="text-xs font-semibold tracking-wider text-left text-gray-600 uppercase bg-white border-b-2"
                >
                  {{ header.question }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th
                  v-for="body in displayedItems"
                  :key="body"
                  class="text-xs font-semibold tracking-wider text-left text-gray-600 uppercase bg-white border-b-2 border-white"
                >
                  <tr
                    v-for="(item, key) in body"
                    :key="key"
                    class="text-xs font-semibold tracking-wider text-left text-gray-600 uppercase bg-white border-b-2 border-white"
                  >
                    <td>
                      {{ item.content }}
                    </td>
                  </tr>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-4">
          <button @click="previousPage" class="elegant-button mr-4">
            Previous
          </button>
          <button @click="nextPage" class="elegant-button">Next</button>
        </div>
      </div>
    </div>
  </div>
  <ImageModal
    v-if="isModalVisible"
    :image-url="modalImageUrl"
    @close="closeImageModal"
  />
</template>

<script setup>
import Breadcrumb from "../partials/BreadcrumbLayout.vue";
import { ref, onMounted, computed, watch } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import ImageModal from "./ImageModal.vue";

const province = ref([]);
const selectedProvince = ref(null);
const city = ref([]);
const selectedCity = ref(null);
const kecamatan = ref([]);
const selectedKecamatan = ref(null);
const kelurahan = ref([]);
const selectedKelurahan = ref(null);

const question = ref([]);
const questionFix = ref([]);
const router = useRouter();
const answerQuestionPairs = ref([]);
let groupedAnswers = {};
let isModalVisible = false;
let modalImageUrl = "";
const itemsPerPage = 50;
let currentPage = 1;

const displayedItems = computed(() => {
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return answerQuestionPairs.value;
});

const previousPage = () => {
  if (currentPage > 1) {
    currentPage--;
  }
  getAnswer();
};

const nextPage = () => {
  currentPage++;
  getAnswer();
};

const showImageModal = (imageUrl) => {
  modalImageUrl = imageUrl;
  isModalVisible = true;
};

const closeImageModal = () => {
  isModalVisible = false;
};

const getQuestion = () => {
  axios
    .get("/api/v1/questionares/headtable", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      question.value = response.data;
    })
    .catch((error) => {
      console.error("Error fetching user data:", error);
    });
};

const getAnswer = () => {
  axios
    .get(
      "/api/v1/answers?items=50&page=" +
        currentPage +
        "&kode=" +
        (selectedKelurahan.value != null
          ? selectedKelurahan.value
          : selectedKecamatan.value != null
          ? selectedKecamatan.value
          : selectedCity.value != null
          ? selectedCity.value
          : selectedProvince.value != null
          ? selectedProvince.value
          : ""),
      { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
    )
    .then((response) => {
      answerQuestionPairs.value = [];
      questionFix.value = [];
      const jsonArray = response.data.data;
      const questions = question.value;
      groupedAnswers = {};
      jsonArray.forEach((answer) => {
        const detailanswer = answer.answers;
        detailanswer.forEach((detailAnswer) => {
          console.log(detailanswer);
          const questionID = detailAnswer.questionare_id;
          if (!groupedAnswers[questionID]) {
            groupedAnswers[questionID] = [];
          } else {
            groupedAnswers[questionID].push(detailAnswer);
          }
        });
      });

      answerQuestionPairs.value = [];
      questions.forEach((question) => {
        if (question.question_type !== "devider") {
          const questionID = question.id;
          questionFix.value.push(question);

          const answersForQuestion = groupedAnswers[questionID];
          console.log(answersForQuestion, "list question");
          answerQuestionPairs.value.push(answersForQuestion);
        }
      });
    })
    .catch((error) => {
      console.error("Error fetching user data:", error);
    });
};

const exportTableDataToCSV = () => {
  if (answerQuestionPairs.value.length === 0) {
    return;
  }

  const csvData = [];
  const headers = Object.keys(answerQuestionPairs.value[0][0]);
  csvData.push(headers.join(","));

  answerQuestionPairs.value.forEach((answers) => {
    answers.forEach((answer) => {
      const row = headers.map((header) => {
        const value = answer[header] || "";
        return `"${value}"`;
      });
      csvData.push(row.join(","));
    });
  });

  const csvBlob = new Blob([csvData.join("\n")], { type: "text/csv" });
  const csvURL = window.URL.createObjectURL(csvBlob);
  const link = document.createElement("a");
  link.href = csvURL;
  link.download = "table_data.csv";
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(csvURL);
  document.body.removeChild(link);
};

const getProvince = () => {
  axios
    .get("https://maps.desapresisi.id/api/provinsis/", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      province.value = response.data;
    })
    .catch((error) => {
      console.error("Error fetching province data:", error);
    });
};

const getCity = () => {
  axios
    .get(
      "https://maps.desapresisi.id/api/kabkot?kode=" + selectedProvince.value,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      city.value = response.data;
    })
    .catch((error) => {
      console.error("Error fetching city data:", error);
    });
};

const getKecamatan = () => {
  axios
    .get(
      "https://maps.desapresisi.id/api/kecamatan?kode=" + selectedCity.value,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      kecamatan.value = response.data;
    })
    .catch((error) => {
      console.error("Error fetching kecamatan data:", error);
    });
};

const getKelurahan = () => {
  axios
    .get(
      "https://maps.desapresisi.id/api/deskel?kode=" + selectedKecamatan.value,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      kelurahan.value = response.data;
    })
    .catch((error) => {
      console.error("Error fetching kelurahan data:", error);
    });
};
const getSelected = () => {
  if (selectedProvince.value != null) {
    getCity();
  }
  if (selectedCity.value != null) {
    getKecamatan();
  }
  if (selectedKecamatan.value != null) {
    getKelurahan();
  }
  getAnswer();
};
watch(selectedKelurahan, (newValue, oldValue) => {
  // This function will be called whenever the value of selectedKelurahan changes
  console.log("Selected Kelurahan changed. New Value:", newValue);
  getAnswer();
  // You can perform any additional actions here when the selection changes.
});

onMounted(() => {
  getProvince();
  getQuestion();
  getAnswer("");
});
</script>

<style scoped>
.table-container {
  max-height: 900px; /* Adjust the height as needed */
  overflow-y: auto;
}
th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px 16px; /* Increased padding for better touch interaction */
  max-width: 1000;
}
.elegant-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold; /* Make text more prominent */
  font-size: 14px; /* Slightly larger font size for better readability */
}
.elegant-button:hover {
  background-color: #0056b3;
}
</style>
