<template>
  <div>
    <canvas ref="chartCanvas"></canvas>
    <button id="loadMoreButton" @click="loadMore">Load More</button>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import Chart from "chart.js/auto";
import axios from 'axios';

export default {
  setup() {
    const chartCanvas = ref(null);
    let myChart;
    const data = ref([]);
    const labelsData = ref([]);

    onMounted(() => {
      const ctx = chartCanvas.value.getContext("2d");

      myChart = new Chart(ctx, {
        type: "line",
        data: {
          labels: labelsData.value.slice(0, 50), // Initial display with the first 50 labels
          datasets: [
            {
              label: "Drawn Line",
              borderColor: "blue",
              data: data.value.slice(0, 50), // Initial display with the first 50 data points
            },
          ],
        },
        options: {
          responsive: true,
          interaction: {
            mode: "nearest",
            axis: "x",
            intersect: false,
          },
        },
      });
    });

    // Function to update the chart with a new set of data
    const updateChart = (start, end) => {
      myChart.data.labels = labelsData.value.slice(start, end);
      myChart.data.datasets[0].data = data.value.slice(start, end);
      myChart.update();
    };

    // Function to load more data
    const loadMore = () => {
      const maxDataPoints = 50; // Number of data points to display at a time
      const start = myChart.data.labels.length;
      const end = start + maxDataPoints;

      if (end < labelsData.value.length) {
        updateChart(start, end);
      } else {
        // Disable the button when all data is loaded
        const loadMoreButton = document.getElementById("loadMoreButton");
        loadMoreButton.disabled = true;
      }
    };

    onMounted(() => {
      // Fetch user data and populate labelsData and data arrays
      axios.get('/api/v1/dashboard/per-user', { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
        .then((response) => {
          response.data.forEach((value) => {
            labelsData.value.push(value.fullname);
            data.value.push(value.total_per_day);
          });

          // Initial display
          updateChart(0, 50);
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
        });
    });

    return {
      chartCanvas,
      loadMore,
    };
  },
};
</script>
