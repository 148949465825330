import { createApp } from "vue";
import VueApexCharts from "vue3-apexcharts";
import DashboardLayout from "./components/DashboardLayout.vue";
import EmptyLayout from "./components/EmptyLayout.vue";
import axios from 'axios';
import "./assets/tailwind.css";

import App from "./App.vue";
import router from "./router";
const app = createApp(App);

axios.defaults.baseURL = 'https://canvassing-core.merdesariset.id'
axios.interceptors.request.use(function (config) {
  config.headers['X-Binarybox-Api-Key'] = process.env.VUE_APP_API_KEY;
  return config;
});
app.component("default-layout", DashboardLayout);
app.component("empty-layout", EmptyLayout);

app.use(router).use(VueApexCharts).mount("#app");
