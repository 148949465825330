<template>
  <!-- Loop through your chart data and generate charts dynamically -->
  <div v-for="(chartData, index) in chartDataList" :key="index">
    <!-- Pass data to the custom chart component -->
    <apexchart
      type="area"
      :options="chartData.options"
      :series="chartData.series"
      :key="index"
      ref="chart"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts';
import axios from 'axios';
import { ref, onMounted } from 'vue';

export default {
  components: {
    apexchart: VueApexCharts,
  },
  setup() {
    // Define chartDataList in your setup
    const chartDataList = ref([]);

    // Define other variables used in your component
    const totalQuestion = ref(0);
    const isLoading = ref(true);

    // Define the selectedKelurahans variable or prop
    const selectedKelurahans = ref([]); // Replace [] with the default value or data structure

    // Define the setItemId function or method
    const setItemId = (itemId) => {
      // Define the logic for setting the item ID
    };

    // Define a function to get questions and answers
    const getQuestionAndAnswers = async () => {
      try {
        // Make an API request to retrieve question data
        const questionResponse = await axios.get('/api/v1/questionares/show?type=checkbox', {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        });

        totalQuestion.value = questionResponse.data.length;

        for (const question of questionResponse.data) {
          // Check if the question is relevant
          if (
            question.question_type === 'checkbox' &&
            question.question !== 'Nama Lengkap Responden' &&
            question.question !== 'Alamat' &&
            question.question !== 'Nomor HP'
          ) {
            // Get answers for the question
            const answerResponse = await getAnswers(question);

            // Create chart data
            const chartData = createChartData(question, answerResponse);

            // Push the chart data to the list
            chartDataList.value.push(chartData);
          }
        }

        isLoading.value = false;
      } catch (error) {
        console.error('Error fetching user data:', error);
        isLoading.value = false;
      }
    };

    // Define a function to get answers for a specific question
    const getAnswers = async (question) => {
      try {
        const response = await axios.get(`/api/v1/dashboard/aggregate-answers?q_id=${question.id}&kode=${selectedKelurahans.value}`, {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        });

        return response.data;
      } catch (error) {
        console.error('Error fetching answers:', error);
        return {}; // Return an empty object or handle the error as needed
      }
    };

    // Define a function to create chart data
    const createChartData = (question, answerData) => {
      const labels = [];
      const data = [];
      let totalValue = 0;

      for (const key in answerData) {
        if (Object.prototype.hasOwnProperty.call(answerData, key)) {
          const response = key;
          labels.push(response);
          data.push(answerData[key]);
          totalValue += answerData[key];
        }
      }

      const chartData = {
        options: {
          title: {
            text: question.question,
            align: 'center',
            margin: 40,
            style: {
              fontSize: '20px',
              fontWeight: 'bold',
              color: '#263238',
            },
          },
          chart: {
            type: 'bar',
            height: 350,
            events: {
              dataPointSelection: (event, chartContext, config) => {
                const data = chartData.series[0].data[config.dataPointIndex];
                setTimeout(() => {
                  setItemId(data.id);
                }, 10);
              },
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: true,
          },
          xaxis: {
            categories: labels,
          },
        },
        series: [
          {
            data: data,
          },
        ],
      };

      return chartData;
    };

    // Call the function to get questions and answers when the component is mounted
    onMounted(() => {
      getQuestionAndAnswers();
    });

    // Return variables and functions for use in the template
    return {
      chartDataList,
      totalQuestion,
      isLoading,
    };
  },
};
</script>
