import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Dashboard from "../views/DashboardLayouts.vue";
import Forms from "../views/FormsLayout.vue";
import Tables from "../views/TablesLayout.vue";
import UIElements from "../views/UIElements.vue";
import Login from "../views/LoginLayout.vue";
import Modal from "../views/ModalLayout.vue";
import Chart from "../views/ChartView.vue";
import Card from "../views/CardView.vue";
import Blank from "../views/BlankView.vue";
import NotFound from "../views/NotFound.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: { layout: "empty" },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/forms",
    name: "Forms",
    component: Forms,
    meta: { requiresAuth: true },
  },
  {
    path: "/cards",
    name: "Cards",
    component: Card,
    meta: { requiresAuth: true },
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
    meta: { requiresAuth: true },
  },
  {
    path: "/ui-elements",
    name: "UIElements",
    component: UIElements,
  },
  {
    path: "/modal",
    name: "Modal",
    component: Modal,
  },
  {
    path: "/aggregate",
    name: "Aggregate",
    component: Chart,
    meta: { requiresAuth: true },
  },
  {
    path: "/blank",
    name: "Blank",
    component: Blank,
  },
  { path: "/:pathMatch(.*)*", component: NotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Navigation guard to check authentication before accessing a route
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  
  if (to.matched.some((record) => record.meta.requiresAuth) && token === null) {
    // If the route requires authentication and the token is null, redirect to the login page
    next({ name: "Login" });
  } else {
    // Continue to the requested route
    next();
  }
});

export default router;
