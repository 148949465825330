<template>
  <div>
    <!-- Breadcrumb -->
    <Breadcrumb breadcrumb="aggregate" />

    <div class="mt-4">
      <h4 class="text-gray-600">Aggregate</h4>

      <div class="mt-6 bg-white">
        <div class="grid grid-cols-4 gap-6">
          <!-- Select Province -->
          <div class="col-span-1">
            <label for="provinceSelect">Select Province:</label>
            <select
              v-model="selectedProvince"
              @change="getCity"
              id="provinceSelect"
              class="block w-full mt-2 p-2 border rounded-md"
            >
              <!-- Populate the options dynamically from the 'province' variable -->
              <option
                v-for="prov in province"
                :value="prov.kode_provinsi"
                :key="prov.kode_provinsi"
              >
                {{ prov.nama_provinsi }}
              </option>
            </select>
          </div>
          <!-- Select Kabkot (City) -->
          <div class="col-span-1">
            <label for="kabkotSelect">Select Kabkot:</label>
            <select
              v-model="selectedCity"
              @change="getKecamatan"
              id="kabkotSelect"
              class="block w-full mt-2 p-2 border rounded-md"
            >
              <!-- Populate the options dynamically based on the selected province -->
              <option
                v-for="c in city"
                :value="c.kode_kabkot"
                :key="c.kode_kabkot"
              >
                {{ c.nama_kabkot }}
              </option>
            </select>
          </div>
          <!-- Select Kecamatan (Sub-district) -->
          <div class="col-span-1">
            <label for="kecamatanSelect">Select Kecamatan:</label>
            <select
              v-model="selectedKecamatan"
              @change="getKelurahan"
              id="kecamatanSelect"
              class="block w-full mt-2 p-2 border rounded-md"
            >
              <!-- Populate the options dynamically based on the selected city -->
              <option
                v-for="kec in kecamatan"
                :value="kec.kode_kecamatan"
                :key="kec.kode_kecamatan"
              >
                {{ kec.nama_kecamatan }}
              </option>
            </select>
          </div>
          <!-- Select Desa (Village) -->
          <div class="col-span-1">
            <label for="desaSelect">Select Desa:</label>
            <select
              v-model="selectedKelurahan"
              id="desaSelect"
              class="block w-full mt-2 p-2 border rounded-md"
            >
              <!-- Populate the options dynamically based on the selected kecamatan -->
              <option
                v-for="desa in kelurahan"
                :value="desa.kode_deskel"
                :key="desa.kode_deskel"
              >
                {{ desa.nama_deskel }}
              </option>
            </select>
          </div>
        </div>

        <!-- Additional dynamic components -->
        <DonutCharDynamic
          :selectedKelurahans="
            selectedKelurahan != null
              ? selectedKelurahan
              : selectedKecamatan != null
              ? selectedKecamatan
              : selectedCity != null
              ? selectedCity
              : selectedProvince != null
              ? selectedProvince
              : ''
          "
        />
        <ReadioDynamicAgregate
          :selectedKelurahans="
            selectedKelurahan != null
              ? selectedKelurahan
              : selectedKecamatan != null
              ? selectedKecamatan
              : selectedCity != null
              ? selectedCity
              : selectedProvince != null
              ? selectedProvince
              : ''
          "
        />
        <SelectDynamicAgregate
          :selectedKelurahans="
            selectedKelurahan != null
              ? selectedKelurahan
              : selectedKecamatan != null
              ? selectedKecamatan
              : selectedCity != null
              ? selectedCity
              : selectedProvince != null
              ? selectedProvince
              : ''
          "
        />
        <CheckboxDynamicAgregate
          :selectedKelurahans="
            selectedKelurahan != null
              ? selectedKelurahan
              : selectedKecamatan != null
              ? selectedKecamatan
              : selectedCity != null
              ? selectedCity
              : selectedProvince != null
              ? selectedProvince
              : ''
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../partials/BreadcrumbLayout.vue";
import DonutCharDynamic from "@/components/charts/DonutDynamic.vue";
import ReadioDynamicAgregate from "@/components/charts/RadioDynamicAgregate.vue";
import SelectDynamicAgregate from "@/components/charts/SelectDynamicAgregate.vue";
import CheckboxDynamicAgregate from "@/components/charts/CheckboxDynamicAgregate.vue";
import axios from "axios";

export default {
  data() {
    return {
      province: [], // Store province data
      selectedProvince: null,
      city: [], // Store city data
      selectedCity: null,
      kecamatan: [],
      selectedKecamatan: null,
      kelurahan: [],
      selectedKelurahan: null,
    };
  },
  components: {
    Breadcrumb,
    DonutCharDynamic,
    ReadioDynamicAgregate,
    SelectDynamicAgregate,
    CheckboxDynamicAgregate,
  },
  methods: {
    getProvince() {
      axios
        .get("https://maps.desapresisi.id/api/provinsis/", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.province = response.data;
        })
        .catch((error) => {
          console.error("Error fetching province data:", error);
        });
    },
    getCity() {
      axios
        .get(
          "https://maps.desapresisi.id/api/kabkot?kode=" +
            this.selectedProvince,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.city = response.data;
        })
        .catch((error) => {
          console.error("Error fetching city data:", error);
        });
    },
    getKecamatan() {
      axios
        .get(
          "https://maps.desapresisi.id/api/kecamatan?kode=" + this.selectedCity,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.kecamatan = response.data;
        })
        .catch((error) => {
          console.error("Error fetching kecamatan data:", error);
        });
    },
    getKelurahan() {
      axios
        .get(
          "https://maps.desapresisi.id/api/deskel?kode=" +
            this.selectedKecamatan,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.kelurahan = response.data;
        })
        .catch((error) => {
          console.error("Error fetching kelurahan data:", error);
        });
    },
  },
  mounted() {
    this.getProvince();
  },
};
</script>
